import * as React from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BarChartIcon from "@mui/icons-material/BarChart";
import CommuteIcon from "@mui/icons-material/Commute";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import GarageIcon from "@mui/icons-material/Garage";
import LayersIcon from "@mui/icons-material/Layers";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const menus = [
  {
    label: "Company",
    icon: CorporateFareIcon,
    link: "/company",
  },
  {
    label: "Franchises",
    icon: CorporateFareIcon,
    link: "/franchises",
  },
  {
    label: "Routes",
    icon: ShareLocationIcon,
    link: "/routes",
  },
  {
    label: "Vehicle List",
    icon: CommuteIcon,
    link: "/vehicle-list",
  },
  {
    label: "User Accounts",
    icon: CommuteIcon,
    link: "/user-accounts",
  },
  {
    label: "Vehicle Billing",
    icon: CommuteIcon,
    link: "/vehicle-billing",
  },
  {
    label: "Vehicle Activation",
    icon: CommuteIcon,
    link: "/vehicle-activation",
  },
];

export const superAdminCrumbs = {
  dashboard: [{ label: "Dashboard", url: "/", is_active: true }],
  companies: [{ label: "Companies", url: "/companies", is_active: true }],
  franchises: [{ label: "Franchises", url: "/franchises", is_active: true }],
  routes: [{ label: "Routes", url: "/routes", is_active: true }],
  route_maker: [{ label: "Route Maker", url: "/route-maker", is_active: true }],
  vehicle_list: [
    { label: "Vehicles List", url: "/vehicle-list", is_active: true },
  ],
  user_accounts: [
    { label: "User Accounts", url: "/user-accounts", is_active: true },
  ],
  super_admin_accounts: [
    {
      label: "Super Admin Accounts",
      url: "/super-admin-accounts",
      is_active: true,
    },
  ],
  vehicle_billing: [
    { label: "Vehicle Billing", url: "/vehicle-billing", is_active: true },
  ],
  vehicle_activation: [
    {
      label: "Vehicle Activation",
      url: "/vehicle-activation",
      is_active: true,
    },
  ],
};
