import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import * as actions from "../src/store/actions/index";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";

import AdminLogin from "./pages/Authentication/AdminLogin";
import CheckLoader from "./components/Loaders/CheckLoader";
import CompanyAdminLayout from "./layouts/CompanyAdminLayout";
import Error from "./pages/Authentication/Error";
import FleetManagerLayout from "./layouts/FleetManagerLayout";
import Loader from "./components/Loaders/Loader";
import Login from "./pages/Authentication/Login";
import Logout from "./pages/Authentication/Logout";
import SuperAdminLayout from "./layouts/SuperAdminLayout";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";

const App = (props) => {
  useEffect(() => {
    props.onGetAuthStorage();
  }, []);

  let authRoutes = null;

  if (props.auth.loading) {
    return <CheckLoader />;
  }

  if (props.hasToken) {
    if (props.auth.type == "admin") {
      authRoutes = <Route path="/*" element={<SuperAdminLayout />} />;
    }
    if (props.auth.type == "client_admin") {
      authRoutes = <Route path="/*" element={<CompanyAdminLayout />} />;
    }
    if (props.auth.type == "client") {
      authRoutes = <Route path="/*" element={<FleetManagerLayout />} />;
    }
  }

  return (
    <>
      <ToastContainer />
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            {authRoutes ? (
              authRoutes
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/admin" element={<AdminLogin />} />
              </>
            )}
            <Route path="/logout" exact={"true"} element={<Logout />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hasToken: state.auth.token !== null ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAuthStorage: () => dispatch(actions.getAuthStorage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
