import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";
import { fleetManagerCrumbs } from "../../constant/menus/fleet-manager-menu";

const BreadCrumbs = (props) => {
  const { route } = props;
  const menus = fleetManagerCrumbs[route];

  return (
    <Box sx={{ backgroundColor: "#EDEDED", padding: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {menus.map((item, index) => {
          if (!item.is_active) {
            return (
              <Link
                underline="hover"
                color={"#F57C00"}
                to={item.url}
                key={index}
              >
                {item.label}
              </Link>
            );
          }

          return (
            <Typography color={"#F57C00"} key={index}>
              {item.label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadCrumbs;
