import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import React, { Component } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

export const XCard = (props) => {
  const { headerTitle, CardFooter, CardRightHeader } = props;

  if (typeof CardRightHeader == "function") {
    return (
      <Card>
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ marginBottom: "0px", marginTop: "0px" }}>
                {headerTitle}
              </h2>
              <CardRightHeader></CardRightHeader>
            </div>
          }
        />

        <Divider variant="middle" />
        <CardContent>{props.children}</CardContent>
        {typeof CardFooter == "function" ? (
          <Divider variant="middle" />
        ) : (
          <span></span>
        )}
        {typeof CardFooter == "function" ? (
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <CardFooter></CardFooter>
          </CardActions>
        ) : (
          <span></span>
        )}
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title={
          <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>
            {headerTitle}
          </h4>
        }
      />
      <Divider variant="middle" />
      <CardContent>{props.children}</CardContent>
      {typeof CardFooter == "function" ? (
        <Divider variant="middle" />
      ) : (
        <span></span>
      )}
      {typeof CardFooter == "function" ? (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <CardFooter></CardFooter>
        </CardActions>
      ) : (
        <span></span>
      )}
    </Card>
  );
};

export const XCard2 = (props) => {
  return (
    <Card
      sx={{ minWidth: 180, minHeight: 80, height: "100%", borderRadius: 2 }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: 130,
            textAlign: "center",
            verticalAlign: "middle",
            paddingTop: 10,
          }}
          component="div"
        >
          {props.count}
        </Typography>
      </CardContent>
    </Card>
  );
};
export const XDialogForm = (props) => {
  const { showDialog, dialogClose, title, maxWidth, fullWidth, Footer } = props;

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => dialogClose(e)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

export const XDialogFullForm = (props) => {
  const { showDialog, dialogClose, title, maxWidth, fullWidth, Footer } = props;

  return (
    <Dialog
      fullScreen
      open={showDialog}
      onClose={(e) => dialogClose(e)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      {Footer ? (
        <DialogActions sx={{ spacing: 10 }}>{Footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};

export const XDialogText = (props) => {
  const { showDialog, dialogClose, title, content, Footer } = props;

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => dialogClose(e)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box sx={{ width: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </Box>
      </DialogContent>
      {typeof Footer == "function" ? (
        <DialogActions>
          <Footer />
        </DialogActions>
      ) : (
        <span></span>
      )}
    </Dialog>
  );
};

export const XSelectField = (props) => {
  const { field, value, placeholder, options, onChange, is_error } = props;

  if (options.length) {
    return (
      <Select
        error={is_error}
        displayEmpty
        fullWidth
        id={field}
        name={field}
        sx={{ width: "100%" }}
        value={value}
        onChange={(e) => onChange(e)}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>

        {options.length ? (
          options.map((item, index) => {
            return (
              <MenuItem key={"item-" + index} value={item.id}>
                {item.label}
              </MenuItem>
            );
          })
        ) : (
          <></>
        )}
      </Select>
    );
  }

  return (
    <Select
      error={is_error}
      displayEmpty
      fullWidth
      id={field}
      name={field}
      sx={{ width: "100%" }}
      value={value}
      onChange={(e) => onChange(e)}
    >
      <MenuItem disabled value="">
        <em>{placeholder}</em>
      </MenuItem>
    </Select>
  );
};
