import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import DataTable from "../../../components/DataGrid";
import QuestionForm from "./QuestionForm";
import { XDialogForm } from "../../../components/materials";
import { connect } from "react-redux";
import questionCols from "../../../constant/table-columns/company/questionnaire-table";
import { questionLists } from "../../../apis/client-services/question-services";

const Questionnaire = (props) => {
  const [tableCols, setTableCols] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();

  useEffect(() => {
    if (questionCols) {
      setTableCols([
        ...questionCols,
        {
          field: "details",
          headerName: "Details",
          renderCell: (params) => {
            return (
              <Button
                variant="contained"
                size="small"
                sx={{ marginBottom: "0px" }}
                onClick={() => {
                  onShowUpdateDialog();
                  setSelectedQuestion(params.row);
                }}
              >
                Details
              </Button>
            );
          },
        },
      ]);
    }
    onLoadData();
  }, []);

  const onShowAddDialog = () => {
    setShowAddDialog(true);
  };
  const onCloseAddDialog = () => {
    setShowAddDialog(false);
  };

  const onShowUpdateDialog = () => {
    setShowUpdateDialog(true);
  };
  const onCloseUpdateDialog = () => {
    setShowUpdateDialog(false);
  };

  const onLoadData = () => {
    setIsLoading(true);
    questionLists().then((res) => {
      if (res) {
        setTableRows(res);
        setShowAddDialog(false);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        sx={{ marginBottom: "10px", marginTop: "10px" }}
        onClick={() => onShowAddDialog()}
        color="primary"
      >
        New Question
      </Button>
      <DataTable
        is_loading={isLoading}
        columns={tableCols}
        rows={tableRows}
        getRowId={(row) => row.q_id}
      />
      <XDialogForm
        showDialog={showAddDialog}
        title={"Add Question Form"}
        maxWidth="sm"
        fullWidth={true}
      >
        <QuestionForm
          onCloseDialog={onCloseAddDialog}
          onLoadData={onLoadData}
          mode="Create"
        />
      </XDialogForm>
      <XDialogForm
        showDialog={showUpdateDialog}
        title={"Update Question Form"}
        maxWidth="sm"
        fullWidth={true}
      >
        <QuestionForm
          onCloseDialog={onCloseUpdateDialog}
          onLoadData={onLoadData}
          mode="Update"
          selectedQuestion={selectedQuestion}
        />
      </XDialogForm>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hasToken: state.auth.token !== null ? true : false,
  };
};

export default connect(mapStateToProps, null)(Questionnaire);
