import instance from "../../config/axios";

export const questionLists = async () => {
  var result = await instance.get("/company/questions");
  if (result.status !== 200 || result.status !== 201) {
    return result.data;
  }
  return false;
};

export const addQuestion = async (form) => {
  var result = await instance.post("/company/questions", form);
  if (result.status !== 200 || result.status !== 201) {
    return result.data;
  }
  return false;
};

export const updateQuestion = async (id, form) => {
  var result = await instance.put(`/company/questions/${id}`, form);
  if (result.status !== 200 || result.status !== 201) {
    return result.data;
  }
  return false;
};
