const moment = require("moment-timezone");

export default [
  {
    field: "question",
    headerName: "Question",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    width: 100,
  },
  {
    field: "date_create",
    headerName: "Date Created",
    width: 120,
    renderCell: (params) => {
      let date = moment(params.row.date_create).tz("Asia/Manila");
      if (date.isValid()) {
        return (
          <span style={{ fontSize: 12 }}>{date.format("MMMM DD, YYYY")}</span>
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      if (params.row.status === "active") {
        return (
          <div>
            <p>ACTIVE</p>
          </div>
        );
      }
      return (
        <div>
          <p>INACTIVE</p>
        </div>
      );
    },
  },
];
