import React, { useEffect, useState } from "react";
import {
  fleetManagementMenus,
  managementMenus,
} from "../../constant/menus/fleet-manager-menu";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import Divider from "@mui/material/Divider";
import { Drawer } from "../../constant/theme";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import detectMob from "../detectMob";
import logo from "../../assets/img/logotm.png";

const DrawerCompanyAdmin = (props) => {
  const { open, toggleDrawer } = props;
  const [expandMenu1, setExpandMenu1] = useState(true);
  const [expandMenu2, setExpandMenu2] = useState(true);

  useEffect(() => {
    if (detectMob()) {
      toggleDrawer();
      setExpandMenu1(false);
      setExpandMenu2(false);
    }
  }, []);

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          <img src={logo} width="80px" style={{ marginLeft: "15px" }} />
        </Typography>
        <IconButton onClick={(e) => toggleDrawer()}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />

      <List component="nav">
        <ListItemButton to={"/"} component={Link}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />

        <ListItemButton onClick={() => setExpandMenu1(!expandMenu1)}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="Management" />
          {!expandMenu1 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {managementMenus.length ? (
              managementMenus.map((item, index) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    to={item.link}
                    component={Link}
                    key={index}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                );
              })
            ) : (
              <span></span>
            )}
          </List>
        </Collapse>

        <Divider sx={{ my: 1 }} />

        <ListItemButton onClick={() => setExpandMenu2(!expandMenu2)}>
          <ListItemIcon>
            <DirectionsBusIcon />
          </ListItemIcon>
          <ListItemText primary="Manage Fleet" />
          {!expandMenu2 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {fleetManagementMenus.length ? (
              fleetManagementMenus.map((item, index) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    to={item.link}
                    component={Link}
                    key={index}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                );
              })
            ) : (
              <span></span>
            )}
          </List>
        </Collapse>

        <Divider sx={{ my: 1 }} />

        <ListItemButton to={"/logout"} component={Link}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default DrawerCompanyAdmin;
