import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";

const Error = () => {
  return (
    <div
      style={{
        textAlign: "center",
        height: "100%",
        width: "100%",
        paddingTop: 25,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Page doesn't exist
      </Typography>
      <Button variant="text" to={"/"} component={Link} color="primary">
        <Typography variant="button" display="block">
          Back to Home
        </Typography>
      </Button>
    </div>
  );
};

export default Error;
