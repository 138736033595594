import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  addQuestion,
  updateQuestion,
} from "../../../apis/client-services/question-services";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import questionsTypesOptions from "../../../constant/questions-types-options";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  question: yup.string().required("Question is required").default(""),
  description: yup.string().default(""),
  q_type: yup.string().required("Type is required").default(""),
});

const DriverForm = (props) => {
  const { control, handleSubmit, formState, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState("Create");
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (props.mode) {
      setMode(props.mode);
    }
  }, [props.mode]);

  useEffect(() => {
    if (props.selectedQuestion) {
      let question = props.selectedQuestion;
      setValue("question", question.question);
      setValue("description", question.description);
      setValue("q_type", question.type);
      if (question.status === "active") {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedQuestion]);

  const onSubmit = (data) => {
    let raw = {
      question: data.question,
      description: data.description,
      type: data.q_type,
    };

    if (status) {
      raw["status"] = "active";
    } else {
      raw["status"] = "inactive";
    }

    setIsSubmitting(true);

    if (mode === "Create") {
      addQuestion(raw)
        .then((res) => {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          reset();
          props.onCloseDialog();
          props.onLoadData();
        })
        .catch((error) => {
          let errorMessage = "An error occured";
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (mode === "Update") {
      updateQuestion(props.selectedQuestion.q_id, raw)
        .then((res) => {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          reset();
          props.onCloseDialog();
          props.onLoadData();
        })
        .catch((error) => {
          let errorMessage = "An error occured";
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const formFooter = (
    <>
      {isSubmitting ? (
        <Box sx={{ width: "100%", paddingTop: 5 }}>
          <LinearProgress />
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          paddingTop: 5,
          width: "100%",
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ color: "#909090" }}
          onClick={() => {
            props.onCloseDialog();
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          type="submit"
          variant="contained"
          size="small"
          color="primary"
          disabled={isSubmitting}
        >
          {mode === "Create" ? "Add" : null}
          {mode === "Update" ? "Update" : null}
        </Button>
      </Box>
    </>
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} sx={{ paddingTop: 1 }}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Controller
            render={({ field }) => (
              <TextField
                label="Question"
                fullWidth
                error={formState?.errors?.question?.message}
                helperText={formState?.errors?.question?.message}
                {...field}
                disabled={isSubmitting}
                InputLabelProps={{ shrink: true }}
              />
            )}
            name="question"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Controller
            render={({ field }) => (
              <TextField
                label="Description"
                fullWidth
                error={formState?.errors?.description?.message}
                helperText={formState?.errors?.description?.message}
                {...field}
                disabled={isSubmitting}
                InputLabelProps={{ shrink: true }}
              />
            )}
            name="description"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <FormControl fullWidth error={formState?.errors?.q_type?.message}>
            <Controller
              name="q_type"
              control={control}
              defaultValue={"technical"}
              render={({ field }) => (
                <>
                  <InputLabel id="select-type-select-label">
                    Select Type
                  </InputLabel>
                  <Select
                    control={control}
                    labelId="select-type-select-label"
                    label="Select Type"
                    {...field}
                    disabled={isSubmitting}
                  >
                    {questionsTypesOptions.map((item, index) => {
                      return (
                        <MenuItem value={item} id={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formState?.errors?.q_type?.message ? (
                    <FormHelperText>
                      {formState?.errors?.q_type?.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={status}
                onClick={(e) => {
                  if (status) {
                    setStatus(false);
                  } else {
                    setStatus(true);
                  }
                }}
              />
            }
            label="Active"
          />
        </FormGroup>
      </Grid>
      {formFooter}
    </Box>
  );
};

export default DriverForm;
