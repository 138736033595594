import * as React from "react";

import ArticleIcon from "@mui/icons-material/Article";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BarChartIcon from "@mui/icons-material/BarChart";
import CommuteIcon from "@mui/icons-material/Commute";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import GarageIcon from "@mui/icons-material/Garage";
import LayersIcon from "@mui/icons-material/Layers";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const managementMenus = [
  {
    label: "Company",
    icon: CorporateFareIcon,
    link: "/company",
  },
  {
    label: "Routes",
    icon: ForkRightIcon,
    link: "/route",
  },
  {
    label: "Franchise",
    icon: ArticleIcon,
    link: "/franchise",
  },
];

export const fleetManagementMenus = [
  {
    label: "Driver",
    icon: PeopleIcon,
    link: "/drivers",
  },
  {
    label: "Dispatcher",
    icon: PeopleIcon,
    link: "/dispatchers",
  },
  {
    label: "Vehicle",
    icon: CommuteIcon,
    link: "/vehicles",
  },
  {
    label: "Tracking",
    icon: ShareLocationIcon,
    link: "/tracking",
  },
  {
    label: "Schedules",
    icon: GarageIcon,
    link: "/schedules",
  },
  {
    label: "Assigning",
    icon: AssignmentIcon,
    link: "/assigning",
  },
  {
    label: "Questionnaire",
    icon: ReceiptIcon,
    link: "/questionnaire",
  },
];

export const companyAdminCrumbs = {
  dashboard: [{ label: "Dashboard", url: "/", is_active: true }],
  // Management
  company: [{ label: "Company", url: "/company", is_active: true }],
  route: [{ label: "Routes", url: "/route", is_active: true }],
  franchise: [{ label: "Franchise", url: "/franchise", is_active: true }],
  // Fleet Management
  drivers: [{ label: "Drivers", url: "/drivers", is_active: true }],
  dispatchers: [{ label: "Dispatchers", url: "/dispatchers", is_active: true }],
  vehicles: [{ label: "Vehicles", url: "/vehicles", is_active: true }],
  tracking: [{ label: "Tracking", url: "/tracking", is_active: true }],
  schedules: [{ label: "Schedules", url: "/schedules", is_active: true }],
  assigning: [{ label: "Assigning", url: "/assigning", is_active: true }],
  questionnaire: [
    { label: "Questionnaire", url: "/questionnaire", is_active: true },
  ],
  // Invoice
  vehicle_invoice: [
    {
      label: "Vehicle Invoice",
      url: "/invoice/vehicle-billing",
      is_active: true,
    },
  ],
  one_time_invoice: [
    {
      label: "One Time Invoice",
      url: "/invoice/one-time-invoice",
      is_active: true,
    },
  ],
  // Inventory
  items: [{ label: "Items", url: "/inventory/items", is_active: true }],
  stocks: [{ label: "Stocks", url: "/inventory/stocks", is_active: true }],
};
