import ArticleIcon from "@mui/icons-material/Article";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CommuteIcon from "@mui/icons-material/Commute";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import GarageIcon from "@mui/icons-material/Garage";
import PeopleIcon from "@mui/icons-material/People";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";

export const managementMenus = [
  {
    label: "Company",
    icon: CorporateFareIcon,
    link: "/company",
  },
  {
    label: "Routes",
    icon: ForkRightIcon,
    link: "/route",
  },
  {
    label: "Franchise",
    icon: ArticleIcon,
    link: "/franchise",
  },
];

export const fleetManagementMenus = [
  {
    label: "Driver",
    icon: PeopleIcon,
    link: "/drivers",
  },
  {
    label: "Dispatcher",
    icon: PeopleIcon,
    link: "/dispatchers",
  },
  {
    label: "Vehicle",
    icon: CommuteIcon,
    link: "/vehicles",
  },
  {
    label: "Tracking",
    icon: ShareLocationIcon,
    link: "/tracking",
  },
  {
    label: "Schedules",
    icon: GarageIcon,
    link: "/schedules",
  },
  {
    label: "Assigning",
    icon: AssignmentIcon,
    link: "/assigning",
  },
];

export const fleetManagerCrumbs = {
  dashboard: [{ label: "Dashboard", url: "/", is_active: true }],
  // Management
  company: [{ label: "Company", url: "/company", is_active: true }],
  route: [{ label: "Routes", url: "/route", is_active: true }],
  franchise: [{ label: "Franchise", url: "/franchise", is_active: true }],
  // Fleet Management
  drivers: [{ label: "Drivers", url: "/drivers", is_active: true }],
  dispatchers: [{ label: "Dispatchers", url: "/dispatchers", is_active: true }],
  vehicles: [{ label: "Vehicles", url: "/vehicles", is_active: true }],
  tracking: [{ label: "Tracking", url: "/tracking", is_active: true }],
  schedules: [{ label: "Schedules", url: "/schedules", is_active: true }],
  assigning: [{ label: "Assigning", url: "/assigning", is_active: true }],
};
