import "./index.css";

import * as Sentry from "@sentry/react";

import App from "./App";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware } from "redux";
import authReducer from "./store/reducer/auth";
import { composeWithDevTools } from "remote-redux-devtools";
import { configureStore } from "@reduxjs/toolkit";
import { injectStore } from "./config/axios";
import reportWebVitals from "./reportWebVitals";
import thunk from "redux-thunk";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

Sentry.init({
  dsn: "https://ce1259238d0145ae82de4849beb3f40c@o4504275175735296.ingest.sentry.io/4504275340623872",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const composeEnhancers = composeWithDevTools({
  realtime: true,
  name: "Triptify",
  hostname: "localhost",
  port: 8000,
});

const store = configureStore(
  {
    reducer: {
      auth: authReducer,
    },
  },
  composeEnhancers(applyMiddleware(thunk))
);

injectStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
