import React, { useEffect, useState } from "react";

import ArticleIcon from "@mui/icons-material/Article";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Collapse from "@mui/material/Collapse";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import Divider from "@mui/material/Divider";
import { Drawer } from "../../constant/theme";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import detectMob from "../detectMob";
import logo from "../../assets/img/logotm.png";

const DrawerCom = (props) => {
  const { open, toggleDrawer } = props;
  const [expandMenu1, setExpandMenu1] = useState(true);

  useEffect(() => {
    if (detectMob()) {
      toggleDrawer();
      setExpandMenu1(false);
    }
  }, []);

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          <img src={logo} width="80px" style={{ marginLeft: "15px" }} />
        </Typography>
        <IconButton onClick={(e) => toggleDrawer()}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemButton to={"/"} component={Link}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />

        <ListItemButton onClick={(e) => setExpandMenu1(!expandMenu1)}>
          <ListItemIcon sx={{ minWidth: "35px" }}>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="Client Management" />
          {!expandMenu1 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} to={"/companies"} component={Link}>
              <ListItemIcon>
                <CorporateFareIcon />
              </ListItemIcon>
              <ListItemText primary="Company" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} to={"/routes"} component={Link}>
              <ListItemIcon>
                <ForkRightIcon />
              </ListItemIcon>
              <ListItemText primary="Route" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} to={"/route-maker"} component={Link}>
              <ListItemIcon>
                <ForkRightIcon />
              </ListItemIcon>
              <ListItemText primary="Route Maker" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} to={"/franchises"} component={Link}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Franchise" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              to={"/vehicle-activation"}
              component={Link}
            >
              <ListItemIcon>
                <DirectionsBusIcon />
              </ListItemIcon>
              <ListItemText primary="Vehicle Activation" />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              to={"/vehicle-list"}
              component={Link}
            >
              <ListItemIcon>
                <DirectionsBusIcon />
              </ListItemIcon>
              <ListItemText primary="Vehicle List" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              to={"/user-accounts"}
              component={Link}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="User Accounts" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              to={"/super-admin-accounts"}
              component={Link}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Admin Accounts" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              to={"/vehicle-billing"}
              component={Link}
            >
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary="Vehicle Billing" />
            </ListItemButton>
          </List>
        </Collapse>

        <Divider sx={{ my: 1 }} />

        <ListItemButton to={"/logout"} component={Link}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default DrawerCom;
