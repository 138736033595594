import axios from "axios";

let store;

export const injectStore = (_store) => {
  store = _store;
};

const instance = axios.create({
  baseURL: `https://api-01.triptify.ph/api`,
  timeout: 30000,
});

instance.interceptors.request.use(
  function (config) {
    if (config.method == "put" || config.method == "post") {
      var data = config.data;
      var obj = {};
      for (var i in data) {
        if (i == "created_at") {
          continue;
        } else if (i == "updated_at") {
          continue;
        } else if (i == "date_created") {
          continue;
        } else if (i == "date_updated") {
          continue;
        }

        if (data[i] != null) {
          obj[i] = data[i];
        }
      }

      config.data = obj;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  config.headers.Authorization = store.getState().auth.token;
  return config;
});

export default instance;
