import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

const DataTable = (props) => {
  const { columns, rows, getRowId, is_loading } = props;

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        autoPageSize
        loading={is_loading}
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          LoadingOverlay: LinearProgress,
        }}
      />
    </div>
  );
};

export default DataTable;
