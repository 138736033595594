import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 240;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
		shouldForwardProp: (prop) => prop !== "open",
	})(({ theme, open }) => ({
	  "& .MuiDrawer-paper": {
	    position: "relative",
	    whiteSpace: "nowrap",
	    width: drawerWidth,
	    transition: theme.transitions.create("width", {
	      easing: theme.transitions.easing.sharp,
	      duration: theme.transitions.duration.enteringScreen,
	    }),
	    boxSizing: "border-box",
	    ...(!open && {
	      overflowX: "hidden",
	      transition: theme.transitions.create("width", {
	        easing: theme.transitions.easing.sharp,
	        duration: theme.transitions.duration.leavingScreen,
	      }),
	      width: theme.spacing(7),
	      [theme.breakpoints.up("sm")]: {
	        width: theme.spacing(9),
	      },
	    }),
	  },
	}));

export const mdTheme = createTheme({
	  palette: {
	    primary: {
	      light: "#ab5600",
	      main: "#f57c00",
	      dark: "#f79633",
	      contrastText: "#fff",
	    },
	    secondary: {
	      light: "#b28704",
	      main: "#ffc107",
	      dark: "#ffcd38",
	      contrastText: "#000",
	    },
	    warning: {
	    	main:"#ffeb3b"
	    },
	    info: {
	    	main:'#3f51b5'
	    }
	  },
	});