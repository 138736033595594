import React from "react";

const SuperAdminDashboard = React.lazy(() =>
  import("../pages/SuperAdminDashboard/SuperAdminDashboard")
);
const Company = React.lazy(() =>
  import("../pages/SuperAdminDashboard/ClientManagement/Company/Company")
);
const Franchise = React.lazy(() =>
  import("../pages/SuperAdminDashboard/ClientManagement/Franchise/Franchise")
);
const Routes = React.lazy(() =>
  import("../pages/SuperAdminDashboard/ClientManagement/Routes/Routes")
);
const RouteMaker = React.lazy(() =>
  import("../pages/SuperAdminDashboard/ClientManagement/RouteMaker/RouteMaker")
);
const VehicleModelList = React.lazy(() =>
  import(
    "../pages/SuperAdminDashboard/ClientManagement/VehicleModelList/VehicleModelList"
  )
);
const UserAccounts = React.lazy(() =>
  import(
    "../pages/SuperAdminDashboard/ClientManagement/UserAccounts/UserAccounts"
  )
);
const SuperAdminAccounts = React.lazy(() =>
  import(
    "../pages/SuperAdminDashboard/ClientManagement/SuperAdminAccounts/SuperAdminAccounts"
  )
);
const VehicleBilling = React.lazy(() =>
  import(
    "../pages/SuperAdminDashboard/ClientManagement/VehicleBilling/VehicleBilling"
  )
);
const VehicleActivation = React.lazy(() =>
  import(
    "../pages/SuperAdminDashboard/ClientManagement/VehicleActivation/VehicleActivation"
  )
);

const SuperAdminRoutes = [
  {
    path: "/",
    exact: true,
    name: "dashboard",
    type: "view",
    component: SuperAdminDashboard,
  },
  {
    path: "/admin",
    exact: true,
    name: "admin",
    type: "view",
    component: SuperAdminDashboard,
  },
  {
    path: "/companies",
    exact: true,
    name: "companies",
    type: "view",
    component: Company,
  },
  {
    path: "/franchises",
    exact: true,
    name: "franchises",
    type: "view",
    component: Franchise,
  },
  {
    path: "/routes",
    exact: true,
    name: "routes",
    type: "view",
    component: Routes,
  },
  {
    path: "/route-maker",
    exact: true,
    name: "route_maker",
    type: "view",
    component: RouteMaker,
  },
  {
    path: "/vehicle-list",
    exact: true,
    name: "vehicle_list",
    type: "view",
    component: VehicleModelList,
  },
  {
    path: "/user-accounts",
    exact: true,
    name: "user_accounts",
    type: "view",
    component: UserAccounts,
  },
  {
    path: "/super-admin-accounts",
    exact: true,
    name: "super_admin_accounts",
    type: "view",
    component: SuperAdminAccounts,
  },
  {
    path: "/vehicle-billing",
    exact: true,
    name: "vehicle_billing",
    type: "view",
    component: VehicleBilling,
  },
  {
    path: "/vehicle-activation",
    exact: true,
    name: "vehicle_activation",
    type: "view",
    component: VehicleActivation,
  },
];

export default SuperAdminRoutes;
