import { Route, Routes, useParams, Navigate } from "react-router-dom";

import AppBarComFleetManager from "../components/AppBars/AppBarComFleetManager";
import Box from "@mui/material/Box";
import BreadCrumbsFleetManager from "../components/BreadCrumbs/BreadCrumbsFleetManager";
import CopyrightText from "../components/CopyrightText";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerFleetManager from "../components/Drawers/DrawerFleetManager";
import Error from "../pages/Authentication/Error";
import FleetManagerRoutes from "../routes/FleetManagerRoutes";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { getClientInfo } from "../apis/client-services/client-info-services";
import { mdTheme } from "../constant/theme";

import * as actions from "../store/actions/index";
import { connect } from "react-redux";

class FleetManageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      company: null,
    };
  }

  componentDidMount() {
    getClientInfo().then((res) => {
      if (res) {
        this.setState({
          company: res,
        });
      }
    });
  }

  toggleDrawer = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  render() {
    const RouteComponent = (props) => {
      let params = useParams();
      return (
        <>
          <BreadCrumbsFleetManager route={props.name} />
          <props.component
            {...params}
            type={props.type}
            company={this.company}
            user_type={"client"}
          />
        </>
      );
    };

    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarComFleetManager
            open={this.state.open}
            toggleDrawer={this.toggleDrawer}
            companyName={this.state.company?.company}
          />
          <DrawerFleetManager
            open={this.state.open}
            toggleDrawer={this.toggleDrawer}
          />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[0]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Routes>
              {FleetManagerRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<RouteComponent {...route} />}
                  />
                );
              })}
              <Route path="*" element={<Error />} />
            </Routes>
            <CopyrightText sx={{ pt: 4 }} />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const HOC = (props) => {
  if (props.auth.expirationDate && !props.auth.loading) {
    const expDate = new Date(parseInt(props.auth.expirationDate) * 1000);
    const currentDate = new Date();
    if (!(expDate > currentDate)) {
      return <Navigate to="/logout" />;
    }
  }

  return <FleetManageLayout />;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    hasToken: state.auth.token !== null ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAuthStorage: () => dispatch(actions.getAuthStorage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HOC);
