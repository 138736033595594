import instance from "../config/axios";

export const doLogin = async (username, password) => {
  var res = await instance.post("/auth/company", {
    strategy: "local",
    username: username,
    password: password,
  });
  return res;
};

export const doAdminLogin = async (username, password) => {
  var res = await instance.post("/auth/admin", {
    strategy: "local",
    username: username,
    password: password,
  });

  return res;
};
