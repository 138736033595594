import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  companyID: null,
  expirationDate: null,
  issuedAt: null,
  type: null,
  staffID: null,
  userID: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
};

const authStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.authData.token,
    companyID: action.authData.companyID,
    expirationDate: action.authData.expirationDate,
    issuedAt: action.authData.issuedAt,
    type: action.authData.type,
    staffID: action.authData.staffID,
    userID: action.authData.userID,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state) => {
  return updateObject(state, {
    token: null,
    companyID: null,
    expirationDate: null,
    issuedAt: null,
    type: null,
    staffID: null,
    userID: null,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const getAuthStorage = (state, action) => {
  return updateObject(state, {
    token: action.authData.token,
    companyID: action.authData.companyID,
    expirationDate: action.authData.expirationDate,
    issuedAt: action.authData.issuedAt,
    type: action.authData.type,
    staffID: action.authData.staffID,
    userID: action.authData.userID,
    error: null,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_GET_STORAGE:
      return getAuthStorage(state, action);
    default:
      return state;
  }
};

export default reducer;
