import * as actionTypes from "./actionTypes";

import { doAdminLogin, doLogin } from "../../apis/auth-services";

import jwt_decode from "jwt-decode";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    authData: authData,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const checkAuthTimeout = (expirationDate) => {
  let expDate = new Date(parseInt(expirationDate) * 1000);
  const currentDate = new Date();
  if (expDate > currentDate) {
    let dif = currentDate.getTime() - expDate.getTime();
    var Seconds_from_T1_to_T2 = dif / 1000;
    var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
    console.log(`Expiration set to ${Seconds_Between_Dates} seconds`);
    return (dispatch) => {
      setTimeout(() => {
        console.log("Timed out");
        dispatch(logout());
      }, Seconds_Between_Dates * 1000);
    };
  }
  return (dispatch) => {
    console.log("Expired Token");
    dispatch(logout());
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("companyID");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("issuedAt");
  localStorage.removeItem("type");
  localStorage.removeItem("staffID");
  localStorage.removeItem("userID");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const getAuthStorageSuccess = (authData) => {
  return {
    type: actionTypes.AUTH_GET_STORAGE,
    authData: authData,
  };
};

export const getAuthStorage = () => {
  return (dispatch) => {
    dispatch(authStart());
    const token = localStorage.getItem("token");
    const companyID = localStorage.getItem("companyID");
    const expirationDate = localStorage.getItem("expirationDate");
    const issuedAt = localStorage.getItem("issuedAt");
    const type = localStorage.getItem("type");
    const staffID = localStorage.getItem("staffID");
    const userID = localStorage.getItem("userID");
    dispatch(checkAuthTimeout(expirationDate));
    dispatch(
      getAuthStorageSuccess({
        token,
        companyID,
        expirationDate,
        issuedAt,
        type,
        staffID,
        userID,
      })
    );
  };
};

export const auth = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    doLogin(username, password)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (typeof response.data.token != "undefined") {
            const { company_id, exp, iat, staff_id, type, user_id } =
              jwt_decode(response.data.token);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("companyID", company_id);
            localStorage.setItem("expirationDate", exp);
            localStorage.setItem("issuedAt", iat);
            localStorage.setItem("type", type);
            localStorage.setItem("staffID", staff_id);
            localStorage.setItem("userID", user_id);
            dispatch(
              authSuccess({
                token: response.data.token,
                companyID: company_id,
                expirationDate: exp,
                issuedAt: iat,
                type: type,
                staffID: staff_id,
                userID: user_id,
              })
            );
            dispatch(checkAuthTimeout(exp));
          } else {
            dispatch(authFail("Authentication error"));
          }
        }
      })
      .catch(() => {
        dispatch(authFail("Authentication error"));
      });
  };
};

export const authAdmin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    doAdminLogin(username, password)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (typeof response.data.token != "undefined") {
            const { company_id, exp, iat, staff_id, type, user_id } =
              jwt_decode(response.data.token);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("companyID", company_id);
            localStorage.setItem("expirationDate", exp);
            localStorage.setItem("issuedAt", iat);
            localStorage.setItem("type", type);
            localStorage.setItem("staffID", staff_id);
            localStorage.setItem("userID", user_id);
            dispatch(
              authSuccess({
                token: response.data.token,
                companyID: company_id,
                expirationDate: exp,
                issuedAt: iat,
                type: type,
                staffID: staff_id,
                userID: user_id,
              })
            );
            dispatch(checkAuthTimeout(exp));
          } else {
            dispatch(authFail("Authentication error"));
          }
        }
      })
      .catch(() => {
        dispatch(authFail("Authentication error"));
      });
  };
};
