import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <p className="text-center">Please Wait...</p>
    </div>
  );
};

export default Loader;
