import Questionnaire from "../pages/ClientDashboard/Questionnaire/Questionnaire";
import React from "react";

const ClientDashboard = React.lazy(() =>
  import("../pages/ClientDashboard/ClientDashboard")
);

const ClientCompany = React.lazy(() =>
  import("../pages/ClientDashboard/ClientManagement/Company/Company")
);
const ClientRoute = React.lazy(() =>
  import("../pages/ClientDashboard/ClientManagement/Route/ViewAllRoutes")
);
const ClientFranchise = React.lazy(() =>
  import("../pages/ClientDashboard/ClientManagement/Franchise/ViewAllFranchise")
);

const ClientDriver = React.lazy(() =>
  import("../pages/ClientDashboard/FleetManagement/Drivers/Drivers")
);
const ViewVehicles = React.lazy(() =>
  import("../pages/ClientDashboard/FleetManagement/Vehicle/ViewVehicles")
);

const ClientDispatcher = React.lazy(() =>
  import(
    "../pages/ClientDashboard/FleetManagement/Dispatchers/ClientDispatcher"
  )
);
const ClientItems = React.lazy(() =>
  import("../pages/ClientDashboard/Inventory/Items/Items")
);
const ClientStocks = React.lazy(() =>
  import("../pages/ClientDashboard/Inventory/Stocks/Stocks")
);
const Tracking = React.lazy(() =>
  import("../pages/ClientDashboard/FleetManagement/Tracking/Tracking")
);
const ClientAssigning = React.lazy(() =>
  import("../pages/ClientDashboard/FleetManagement/Assigning/ClientAssigning")
);
const Schedules = React.lazy(() =>
  import("../pages/ClientDashboard/FleetManagement/Schedules/Schedules")
);

const FleetManagerRoutes = [
  {
    path: "/",
    exact: true,
    name: "dashboard",
    type: "view",
    component: ClientDashboard,
  },
  {
    path: "/company",
    exact: true,
    name: "company",
    type: "view",
    component: ClientCompany,
  },
  {
    path: "/route",
    exact: true,
    name: "route",
    type: "view",
    component: ClientRoute,
  },
  {
    path: "/franchise",
    exact: true,
    name: "franchise",
    type: "view",
    component: ClientFranchise,
  },
  {
    path: "/drivers",
    exact: true,
    name: "drivers",
    type: "view",
    component: ClientDriver,
  },
  {
    path: "/dispatchers",
    exact: true,
    name: "dispatchers",
    type: "view",
    component: ClientDispatcher,
  },
  {
    path: "/vehicles",
    exact: true,
    name: "vehicles",
    type: "view",
    component: ViewVehicles,
  },
  {
    path: "/tracking",
    exact: true,
    name: "tracking",
    type: "view",
    component: Tracking,
  },
  {
    path: "/schedules",
    exact: true,
    name: "schedules",
    type: "view",
    component: Schedules,
  },
  {
    path: "/assigning",
    exact: true,
    name: "assigning",
    type: "view",
    component: ClientAssigning,
  },
  {
    path: "/inventory/items",
    exact: true,
    name: "items",
    type: "view",
    component: ClientItems,
  },
  {
    path: "/inventory/stocks",
    exact: true,
    name: "stocks",
    type: "view",
    component: ClientStocks,
  },
  {
    path: "/questionnaire",
    exact: true,
    name: "questionnaire",
    type: "view",
    component: Questionnaire,
  },
];

export default FleetManagerRoutes;
