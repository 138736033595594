import * as actions from "../../store/actions/index";
import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import CopyrightText from "../../components/CopyrightText";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import LinearProgress from "@mui/material/LinearProgress";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Lottie from "lottie-react";
import Paper from "@mui/material/Paper";
import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import triptify from "../../assets/img/logotm.png";
import waves from "../../assets/animations/waves.json";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  username: yup
    .string()
    .min(4, "Username must be at least 4 characters")
    .required("Username is required"),
  password: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .required("Password is required"),
});

const theme = createTheme({
  palette: {
    primary: {
      light: "#b28704",
      main: "#ffc107",
      dark: "#ffcd38",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ab5600",
      main: "#f57c00",
      dark: "#f79633",
      contrastText: "#000",
    },
  },
});

const Login = (props) => {
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    props.onAuth(data.username, data.password);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", overflowY: "hidden" }}
      >
        <CssBaseline />
        <Hidden mdDown>
          <Grid
            item
            xs={0}
            sm={0}
            md={8}
            sx={{
              backgroundImage: `url(${triptify})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "50%",
              backgroundPositionY: "25%",
              backgroundPositionX: "center",
            }}
          >
            <Container>
              <Lottie
                animationData={waves}
                loop={true}
                width={"100%"}
                height={"100%"}
                rendererSettings={{
                  preserveAspectRatio: "xMidYMid slice",
                }}
              />
            </Container>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          component={Paper}
          elevation={2}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h5" variant="h5">
              Sign in
            </Typography>
            {props.auth.error ? (
              <Alert severity="error" sx={{ marginTop: 2 }}>
                {props.auth.error}
              </Alert>
            ) : null}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={0} sx={{ paddingTop: 5 }}>
                <Grid item md={12} sm={12} xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Username"
                        margin="normal"
                        fullWidth
                        id="username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        error={formState?.errors?.username?.message}
                        helperText={formState?.errors?.username?.message}
                        {...field}
                        disabled={props.auth.loading}
                      />
                    )}
                    name="username"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Password"
                        margin="normal"
                        fullWidth
                        name="password"
                        id="password"
                        autoComplete="current-password"
                        error={formState?.errors?.password?.message}
                        helperText={formState?.errors?.password?.message}
                        type="password"
                        {...field}
                        disabled={props.auth.loading}
                      />
                    )}
                    name="password"
                    control={control}
                  />
                </Grid>
              </Grid>
              {props.auth.loading ? (
                <Box>
                  <LinearProgress />
                </Box>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <CopyrightText sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    loading: state.auth.loading,
    error: state.auth.error,
    hasToken: state.auth.token !== null ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
