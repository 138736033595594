import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const CheckLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "100px",
      }}
    >
      <div>
        <p className="text-center">
          <strong>Please wait, we're checking your identity.</strong>
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </div>
    </div>
  );
};

export default CheckLoader;
